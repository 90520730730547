.Wrapper {
	position: relative;

	--animation-time: var(--animation-time-medium);
}

.Tooltip {
	position: absolute;
	top: 100%;
	margin-top: 8px;
	left: 50%;
	padding: 24px;

	max-width: 210px;
	min-width: 210px;

	background: radial-gradient(
			56.93% 56.93% at 50% 50%,
			rgba(206, 183, 255, 0) 0%,
			rgba(103, 79, 255, 0.2) 100%
		),
		linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
	box-shadow: 0px 5px 20px rgba(60, 161, 255, 0.7);

	border-radius: var(--box-radius);
	transform: translateX(-50%);

	cursor: default;

	z-index: 10;
}

.Tooltip::before {
	content: ' ';
	position: absolute;
	bottom: 100%;
	left: 50%;
	width: 50%;
	height: 20px;

	transform: translateX(-50%);
}
