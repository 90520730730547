.bar {
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	width: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.bar::-webkit-scrollbar {
	display: none;
}

.bar > *:not(:first-child) {
	margin-left: 17px;
}

.Fade {
	/* TODO: Implement the fade */
}
