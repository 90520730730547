.ZNALink > a {
	position: relative;
	transition: all var(--animation-time-short) ease-in-out;
	font-weight: 700;
}

.ZNALink > a::before {
	transition: opacity var(--animation-time-medium) ease-in-out;
	content: ' ';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
	width: calc(100% - 16px);
	box-shadow: 0 0 20px 13px rgba(255, 255, 255, 0.3);
	opacity: 0;
}

.ZNALink > a:last-of-type::before {
	opacity: 1;
}

.ZNALink > a:not(:last-child):hover::before {
	opacity: 1;
}

.ZNALink > a:not(:last-child):hover {
}

.ZNALink > a:last-child::before {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
	width: calc(100% - 16px);
	box-shadow: 0 0 20px 13px #3ca1ff;
	z-index: -1;
}
