.Loading {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.Image {
	height: 100%;
	width: 100%;
	transition: opacity var(--animation-time-medium) ease-in-out;
}

.Video {
	background-color: rgba(0, 0, 0, 0.4);
}

.Spinner {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border-top: 2px solid var(--color-accent-1);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
