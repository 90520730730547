.futureButton {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 80px;
	margin-left: 30px;

	height: 32px;
	padding-top: 4px;
	padding-left: 5px;
	padding-right: 5px;
	z-index: 2;

	background: rgba(60, 161, 255, 0.15);

	border-radius: var(--box-radius);

	cursor: pointer;

	transition: opacity var(--animation-time-short) ease-in-out;
}

/* 
 * Border - we need to separate it into a psuedoelement to get the glow effect 
 * This didn't quite work out as I intended it to - fix later
 */
/*.futureButton:after {
	content: ' ';
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	transition: border-color 0.3s ease-in-out;

	/* box-shadow: 0px 0px 7px -px rgba(0, 178, 255, 0.7); */


.content {
	margin-left: 8px;
	margin-right: 8px;
	color: white;

	text-align: center;

	font-size: var(--font-size-default);
	font-weight: 700;
	
	text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7);
	transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
}

.iconImage {
	height: 13px;
	width: 13px;

	background-image: url('./arrow.svg');
	background-size: 13px 13px;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 50%;

	transition: filter 0.3s ease-in-out;
}

.iconImage:not(:first-of-type) {
	transform: rotate(180deg);
}

@keyframes expand {
	0% {
		opacity: 1;
		left: 50%;
		height: 0;
		width: 0;
		border-radius: 0;
	}
	25% {
		/* opacity: 1; */
		box-shadow: 0px 0px 10px 5px rgba(0, 209, 255, 0.1);
		/* top: 50%; */
		/* left: calc(50% - 2px); */

		/* width: 1px;  */
		/* height: 0; */
	}
	70% {
		/* opacity: 1; */
		/* top: 0; */
		/* left: calc(50% - 2px); */

		/* width: 1px;  */
		/* height: 100%; */
	}
	100% {
		opacity: 1;
		box-shadow: 0px 0px 10px 5px rgba(0, 209, 255, 0.1);
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
		border-radius: 18px;
	}
}

@keyframes shrink {
	100% {
		opacity: 0;
		top: 50%;
		left: 50%;
		height: 0;
		width: 0;
		border-radius: 0;
	}
	70% {
		opacity: 1;
		top: 50%;
		left: calc(50% - 2px);

		width: 2px;
		height: 0;
	}
	25% {
		opacity: 1;
		top: 0;
		left: calc(50% - 2px);

		width: 2px;
		height: 100%;

		box-shadow: 0px 0px 10px 5px rgba(0, 209, 255, 0.1);
	}
	0% {
		opacity: 1;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
		border-radius: 18px;
		box-shadow: 0px 0px 5px 5px rgba(0, 209, 255, 0.1);
	}
}

.wash {
	position: absolute;
	top: 50%;
	left: 50%;

	height: 0;
	width: 0;

	background: rgba(0, 209, 255, 0.1);
	box-shadow: 0px 0px 10px 5px rgba(0, 209, 255, 0.1);

	transition: background 0.3s ease-in-out;

	opacity: 0;
}

/* SELECTED STYLING */

.selected:after {
	border-color: white;
}

.selected .wash {
	transition: box-shadow 0.3s ease-in-out;
	background: rgba(255, 255, 255, 0);
	box-shadow: 0px 0px 20px 10px rgba(255, 255, 255, 0.3);

	opacity: 1;
	top: 50%;
	left: 8px;

	width: calc(100% - 16px);
	height: 0;
	border-radius: 18px;

	animation: expand;
	animation-play-state: paused !important;
}

.selected {
	background: rgba(0, 209, 255, 0.05);
}

.selected .iconImage {
	filter: grayscale(100%) brightness(1000%);
}

.selected .content {
	color: white;
	text-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 0);
}

/* HOVER STYLING */

.wash.hovered {
	animation: shrink 0.5s;
	animation-timing-function: ease-in-out;
}

.futureButton:hover:not(.selected) .wash {
	animation: expand 0.2s forwards;
	animation-timing-function: ease-in-out;
}

/* Glow button */

.glow {
	background: rgba(0, 209, 255, 0.1);
	opacity: 1;
}

.glow:after {
	border-color: white;
}

.glow:before {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 0;

	box-shadow: 0px 0px 40px 15px rgba(0, 178, 255, 0.5);
	z-index: -1;
}

.glow .content {
	color: white;
	text-shadow: var(--glow-text-white);
}

.Loading {
	padding-top: 0;
}

.Spinner {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border-top: 2px solid var(--color-accent-1);
	animation: spin 1s linear infinite;
}

.glow .Spinner {
	border-top: 2px solid white;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
