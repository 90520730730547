.popup{
    display: inline-block;
    width: 640px;
    margin: 0;
  
    border-radius: 16px;
  
    background: 
    radial-gradient(56.93% 56.93% at 50% 50%, 
    rgba(206, 183, 255, 0) 0%, 
    rgba(103, 79, 255, 0.2) 100%);
}
  
.text {
    color: white;
    font-size: 1.8em;

    margin-top: 38.5px;
    margin-bottom: 20px;

    text-shadow:
    0 0 5px white;

    font-family: Eurostile;
    font-style: normal;
    font-weight: bold;
}

.green{
    color: rgba(60, 193, 59, 1);
    text-shadow: 0px 0px 7px rgba(0, 178, 255, 0.7);
}