.NumberButton {
	position: relative;
	width: 32px;
	height: 32px;
	padding-top: 4px;

	background: none;
	border-radius: 16px;

	color: white;
	font-weight: 700;
	text-align: center;
}

.NumberButton::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	/* background: rgba(0,0,0,0.3); */

	border-radius: 16px;
	border-left: 2px solid white;
	border-right: 2px solid white;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;

	transform: rotate(0deg);

	transition: transform var(--animation-time-medium) ease-in-out;
}

.Rotating::before {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

.NumberButton::after {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 50%;
	height: 0px;
	width: 0px;
	border-radius: var(--box-radius);
	z-index: -2;

	transition: box-shadow var(--animation-time-medium) ease-in-out;
}

.NumberButton:hover::after {
	box-shadow: 0 0 20px 13px rgba(255, 255, 255, 0.5);
}

@-webkit-keyframes rotating {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
