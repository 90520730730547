.wallet {
	position: relative;
}

.wallet > img {
	position: absolute;
	top: 50%;
	left: 24px;

	transform: translateY(-50%);

	height: 24px;
}

.wallet > span {
	position: absolute;
	top: 10px;
	left: 55px;
	color: var(--color-accent-1);
	font-size: 12px;
}

.wallet input {
	width: 100%;
	padding: 22.5px 61px 6.5px 54px;

	text-align: left;
	font-size: 16px;
	font-weight: 400;
	line-height: 19.2px;

	background: linear-gradient(
		94.23deg,
		rgba(60, 161, 255, 0.2) 0%,
		rgba(30, 81, 128, 0.2) 100%
	);
	color: #dadcdf;
	border: 1px solid #88d7ff;
	font-weight: 700;

	border-radius: 30px;
}

.wallet button {
	position: absolute;
	top: 12px;
	right: 27px;

	padding: 0;
	margin: 0;

	height: 26px;
	width: 26px;

	background-image: url('./assets/copy-icon.svg');
	background-position: center;
	background-size: cover;
	background-color: rgba(0, 0, 0, 0);

	transition: transform var(--animation-time-short) ease-in-out;
}

.wallet button:hover {
	transform: scale(1.1);
}
