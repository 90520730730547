.Dropdown {
	position: relative;
}

.Drawer {
	position: absolute;
	top: calc(100% + 8px);
	left: 50%;
	padding: 8px 0;

	width: calc(100% + 58px);

	background: radial-gradient(
			56.93% 56.93% at 50% 50%,
			rgba(206, 183, 255, 0) 0%,
			rgba(103, 79, 255, 0.2) 100%
		),
		linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
	box-shadow: 0px 5px 20px rgba(60, 161, 255, 0.7);

	border-radius: var(--box-radius);
	transform: translateX(-50%);

	z-index: 10;
	overflow: hidden;
}

.Drawer li {
	position: relative;
	padding: 16px 32px;
	cursor: pointer;
}

.Drawer li::before {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
	width: 85%;
	height: 5;
	background: red;
	z-index: -1;
	box-shadow: 0px 0px 20px 20px rgba(60, 161, 255, 0.8);

	opacity: 0;
	transition: opacity 0.1s ease-in-out;
}

.Drawer li:hover {
	background: rgba(60, 161, 255, 0.05);
}

.Drawer li:hover::before {
	opacity: 1;
}

.Selected {
	background: rgba(60, 161, 255, 0.05) !important;
}

.Selected::before {
	opacity: 0 !important;
}
