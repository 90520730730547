.Container{
	position: relative;
}

.TextInput {
	width: 100%;
	padding: 16.5px 24px 12.5px 24px;

	border-radius: 24px;

	color: white;

	background: var(--background-field-bg);
	transition: border var(--animation-time-medium) ease-in-out;
}

.ethlogo{
	padding: 16.5px 24px 12.5px 50px;
}

.Error {
	border: 1px solid var(--color-invalid);
}

.TextInput::placeholder {
	color: #dedede;
}

.ErrorMessage {
	display: block;
	margin-top: 12px;

	color: var(--color-invalid);
	font-size: 14px;
	font-weight: 700;
	text-align: center;
}
