.connect {
	width: 304px;
	position: relative;

	background: var(--background-blue-card-wilder);
	border-radius: var(--box-radius);

	overflow: hidden;

	filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.5));
}

.connect hr {
	margin: 0 33px;
}

.header {
	width: 100%;
	padding-top: 38px;
	padding-bottom: 22px;
	text-align: center;
}

.header h3 {
	font-size: 22px;
	line-height: 30px;
	color: white;
}

.connect ul {
	width: 100%;
	padding: 18px 0;
}

.wallet {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	padding: 4px 40px;

	color: white;
	font-size: 18px;

	cursor: pointer;
}

.wallet:not(:first-of-type) {
	margin-top: 9px;
}

.wallet::before {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
	width: 85%;
	height: 0;
	box-shadow: 0px 0px 20px 20px rgba(60, 161, 255, 0.8);
	z-index: -1;

	opacity: 0;
	transition: opacity 0.1s ease-in-out;
}

.wallet:hover {
	background: rgba(60, 161, 255, 0.05);
}

.wallet:hover::before {
	opacity: 1;
}

.wallet div {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 40px;
	height: 40px;

	border-radius: 50%;

	background-color: rgba(0, 209, 255, 0.1);
}

.wallet img {
	height: 36px;
	width: 36px;
	object-fit: contain;
}

.Disconnect {
	width: 100%;
}

.Disconnect button {
	margin: 18px auto;
}

.footer {
	width: 100%;
	text-align: center;
	font-size: 14px;
	padding: 18px;
}

.footer p {
	color: #dadcdf;
	line-height: 22px;
	padding: 0;
}

.footer p a {
	color: var(--color-accent-1);
	text-decoration: none;
}
