.Overlay {
	--speed: var(--animation-time-short);

	display: flex;
	justify-content: center;

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	min-height: 100vh;
	height: 100%;
	overflow-y: scroll;

	z-index: 9000;
	opacity: 0;

	background: rgba(0, 0, 0, 0.3);
	transition: backdrop-filter 0.2s;
}

.FullScreen {
	height: 100%;
	width: 100%;
}

.FullScreen > div {
	height: 100%;
}

.Container > * {
	margin: 0 auto;
}

.Centered {
	align-items: center;
}

.Centered > div > div {
	margin: 0 auto;
}

.Image {
	text-align: center;
}

.Overlay > div {
	padding: 64px;
	width: 100%;
}

.Open {
	animation: open var(--speed) ease-in-out;
	opacity: 1;
	backdrop-filter: blur(30px) opacity(1);
}

.Closed {
	animation: close var(--animation-time-short) ease-in-out;
	opacity: 0;

	backdrop-filter: blur(30px) opacity(0);
}

@keyframes open {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes close {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
